@import 'src/styles/vars.scss';

.container {
	height: 100%;
	width: 100%;
	background-color: var(--default);

	.imageContainer {
		position: relative;
		overflow: hidden;

		.image {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.backdrop {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: var(--darkEmerald);
			opacity: 0.2;
			filter: blur(5px);
		}

		.disclaimerContainer {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 34px;
			z-index: 1;
			width: 400px;
			padding: 28px 24px;
			background: rgba(255, 255, 255, 0.1);
			border-radius: 48px;
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);

			color: var(--default);
			font-size: 16px;
			text-align: center;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

			.text {
				font-size: 18px;
				font-weight: 500;
				line-height: 25px;
				letter-spacing: 0.45px;
				margin-bottom: 18px;
				text-align: start;
			}

			.name {
				font-size: 16px;
				font-weight: 300;
				line-height: 25px;
				letter-spacing: 0.4px;
				text-align: start;
			}
		}

		@media (max-width: $xl) {
			display: none;
		}
	}

	.content {
		height: 100%;
		width: 100%;
		position: relative;
		flex: 1;

		.logo {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 32px;
		}
	}
}
