.profileMenuList {
	padding-left: 8px;
	list-style-type: unset;

	li {
		position: relative;

		&::before {
			content: '•';
			position: absolute;
			inset: auto auto auto -8px;
		}
	}
}
