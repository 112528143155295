@import 'src/styles/mixins.scss';

.container {
	width: 100vw;
	height: 100vh;

	.chunkErrorContainer {
		max-width: 352px;

		.title {
			@include title2;
		}

		.text {
			@include text;
			text-align: center;
		}
	}
}
