.tour {
	:global(.ant-tour-arrow) {
		&::before {
			background: #7ed5c5;
		}
	}
	:global(.ant-tour-content) {
		:global(.ant-tour-inner) {
			overflow: hidden;

			:global(.ant-tour-close) {
				color: var(--default);
			}
		}

		:global(.ant-tour-cover) {
			text-align: left;
			padding: 0;
		}

		:global(.ant-tour-footer) {
			align-items: center;
			padding: 0 16px 24px;
		}
	}
}
