.track-vertical {
	width: 4px !important;
	right: 2px;
	bottom: 2px;
	top: 2px;
	border-radius: 20px;
}

.thumb-vertical {
	cursor: pointer;
	border-radius: inherit;
	background-color: #00ac8e;
}
