.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: #bfbfbf;
	text-shadow: none;
}

.ant-btn {
	gap: 8px;
	padding: 8px 16px;
	&:hover {
		fill: var(--corvusGreen);
	}
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
	margin-left: 24px;
}

.ant-btn-default:disabled,
.ant-btn-default.ant-btn-disabled {
	background-color: #f5f5f5;
}

.ant-btn-icon {
	padding: 0;
}
