.ant-modal {
	top: 45px;
}

[class*='ant-modal-content'] {
	padding: 0 !important;
}

[class*='ant-modal-header'] {
	text-align: center;

	.ant-modal-title {
		font-weight: 600;
		font-size: 24px;
		line-height: 24px;
		margin: 0;
	}
}

.ant-modal .ant-modal-header {
	padding: 48px 48px 23px;
	margin-bottom: 0;
}

[class*='ant-modal-body'] {
	padding: 16px 48px !important;

	[class*='ant-form-item-control-input-content'] > .ant-input {
		padding: 8px 12px;
	}
}

[class*='ant-modal-footer'] {
	display: flex;
	justify-content: space-between;
	margin: 0 !important;
	padding: 16px 48px 48px !important;
}

[class*='ant-modal-close-icon'] {
	color: var(--mainGray);
}
