.ant-form-item {
	margin-bottom: 16px;
}

[class*='ant-form-item-label'] {
	label {
		font-weight: 600;
		font-size: 16px !important;
		line-height: 22px;

		& > div {
			font-size: 16px;
		}

		&::before {
			display: none !important;
		}
	}
}

.ant-form-item-label > label::after {
	display: none;
}

[class*='ant-form-item-explain'] {
	font-size: 12px;
	line-height: 16px;
	[class*='ant-form-item-explain'] {
		padding-top: 8px;
	}
}
