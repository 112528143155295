.ant-select-disabled.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
	border-color: var(--gray6);
	color: var(--gray8);
}

.ant-select-dropdown .ant-select-item-option-disabled.ant-select-item-option-selected {
	color: var(--gray5);
	background-color: var(--gray2);
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
	background-color: #f5f5f5;
	border: 1px solid #f0f0f0;
}

.ant-select-dropdown .ant-select-item {
	border-radius: 0;
}

.ant-select-multiple .ant-select-selector {
	padding: 3px 8px;
}

.ant-select-multiple .ant-select-selection-item {
	margin: 4px 0;
	height: 24px;
	border-radius: 6px !important;
	margin-inline-end: 8px !important;
	align-items: center;

	.ant-select-selection-item-remove {
		color: unset;
	}
}

.ant-select-multiple .ant-select-selector:after {
	margin: 0;
}
.ant-select-dropdown {
	padding: 0;
}

.ant-menu-light {
	&:not(.ant-menu-horizontal) {
		.ant-menu-item {
			&:not(.ant-menu-item-selected):hover {
				background-color: transparent;
			}
		}
	}
}

.ant-select-dropdown
	.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
	.ant-select-item-option-state {
	color: white;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 12px;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
	color: var(--gray5);
	background-color: var(--gray2);
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
		.ant-select-customize-input
	):not(.ant-pagination-size-changer):not(.ant-select-status-error)
	.ant-select-selector {
	box-shadow: 0px 0px 4px 0px #00ac8e !important;
}

.ant-select-arrow {
	color: var(--black2) !important;
}
