body {
	margin: 0;
}

h1 {
	margin-top: 0;
	margin-bottom: 0.5em;
}

h3 {
	margin: 0;
}
